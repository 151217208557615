import * as React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  return (
    <div>
      <title>404 - Error la página no existe</title>
      <h1>
        La página que busca no existe o no está disponible en este momento{' '}
        <span role='img' aria-label='Emoji con cara de frustración'>
          😖
        </span>{' '}
        .
      </h1>
      <p>
        Lo sentimos{' '}
        <span role='img' aria-label='Emoji con cara pensativa'>
          😔
        </span>{' '}
        pero no pudimos encontrar el contenido que busca.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to='/'>Volver al inicio</Link>.
      </p>
    </div>
  )
}

export default NotFoundPage
